import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import { IconTypes } from '../../../components/Icon/Icon';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import { useLocaleContext } from '../../../context/Locale';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import Link from '../../../components/Link/Link';

type Props = PageProps & WithTranslation;

const DepositsFAQ: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const layoutOptions: LayoutOptions = {
    seo: {
      title: 'FAQ',
    },
    // page: {
    //   title: 'FAQ',
    // },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.MANGO_YELLOW,
        heroImage: {
          fileName: 'hero-deposits.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getAnswer = (item: any, path: string) => {
    const link =
      item.link.type === 'external' ? (
        <ExternalLink href={(CommonData.websites as any)[item.link.key][activeLocaleTag]} />
      ) : (
        <Link
          to={getPathFromRouteKey(
            item.link.key.split('.').reduce((accum: any, next: any) => accum[next], RouteKeys)
          )}
        />
      );

    return (
      <p>
        <Trans components={[link]}>{item[path]}</Trans>
      </p>
    );
  };

  const getFAQAccordion = () => {
    const list = t('deposits.faq', { returnObjects: true }) as any[];

    return (
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {list.map((item) => {
          return (
            <AccordionItem buttonOptions={{ label: item.question }} key={item.question}>
              {item.link ? (
                getAnswer(item, 'answer')
              ) : (
                <p>
                  <Trans>{item.answer}</Trans>
                </p>
              )}
              {item.list && item.list.length ? (
                <ul>
                  {item.list.map((i: string) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              ) : null}
              {item.answerTwo ? getAnswer(item, 'answerTwo') : null}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Layout options={layoutOptions} className="Deposits">
      <div className="grid-container Deposits__generic-gap">
        <div className="row row-wrap Deposits__row-flex">
          <section className="column column-60">
            <div className="Deposits__generic-gap">
              <h2 id="deposits-faq">
                {t(`${LocaleNameSpaceKeys.COMMON}:frequentlyAskedQuestions`)}
              </h2>
              {getFAQAccordion()}
            </div>
          </section>
          <aside className="column column-33">
            <div className="Deposits__generic-gap Deposits__page-menu">
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                {t('deposits.buttonLabelDeposits')}
              </ButtonLink>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS.CURRENT_DEPOSIT_RATES)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                {t('deposits.currentDeposits.buttonLabel')}
              </ButtonLink>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.DEPOSITS)(DepositsFAQ);
